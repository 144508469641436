import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db } from "../Firebase/firebase";
import { getDocs, addDoc, setDoc, collection, doc } from "firebase/firestore";
import { UserContext } from "../UserContext";
import { useContext } from "react";
import "./timeSlotsPage.css";

const TimeSlotsPage = () => {
    const { professorId, date } = useParams(); // Get professorId and date from the URL
    const [timeSlotGroup, setTimeSlotGroup] = useState(null);
    const { currentUser, role } = useContext(UserContext); // Get current user and role from UserContext
    const navigate = useNavigate();
    const [title, setTitle] = useState("");
    const [notes, setNotes] = useState("");
    const [selectedGroup, setSelectedGroup] = useState("");
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [groups, setGroups] = useState([]);

    // Fetch the time slot group for the selected date
    useEffect(() => {
        const fetchTimeSlotGroup = async () => {
            try {
                console.log("Fetching time slot groups...");
                const timeSlotGroupsData = await getDocs(collection(db, "users", professorId, "timeSlotGroups"));
                const allGroups = timeSlotGroupsData.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                // Find the time slot group for the specified date
                const selectedGroup = allGroups.find(
                    (group) => new Date(group.date).toISOString().split("T")[0] === date
                );

                if (selectedGroup) {
                    // Sort the slots by start time for display purposes
                    selectedGroup.slots.sort((a, b) => new Date(a.start) - new Date(b.start));
                    setTimeSlotGroup(selectedGroup);
                    console.log("Time slot group found:", selectedGroup);
                } else {
                    setTimeSlotGroup(null);
                    console.log("No time slot group found for the selected date.");
                }
            } catch (error) {
                console.error("Error fetching time slot group:", error);
            }
        };
        fetchTimeSlotGroup();
    }, [professorId, date]);

    // Fetch group from database
    useEffect(() => {
        const fetchGroups = async () => {
            try {
                const groupsData = await getDocs(collection(db, "groups"));
                const groupsList = groupsData.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setGroups(groupsList);
            } catch (error) {
                console.error("Error fetching groups:", error);
            }
        };

        fetchGroups();
    }, []);

    // Function to format the time to '01:00 PM' format
    const formatTime = (timeString) => {
        const options = { hour: '2-digit', minute: '2-digit', hour12: true };
        return new Date(timeString).toLocaleTimeString('en-US', options);
    };

    // Handle booking a time slot
    const handleBookSlot = async (slot) => {
        console.log("Booking slot clicked:", slot);
    
        if (role !== "Student") {
            alert("Only students can book time slots.");
            return;
        }
    
        if (!title) {
            console.log("Title is required but not provided.");
            alert("Please provide a title for the meeting.");
            return;
        }

        let attendees = [currentUser.uid, ...selectedUsers];
        let groupName = "";

        // Add group as attendees
        if (selectedGroup) {
            const selectedGroupObj = groups.find(group => group.id === selectedGroup);
            if (selectedGroupObj) {
                attendees = [...new Set([...attendees, ...selectedGroupObj.members])];
                groupName = selectedGroupObj.name; // Use the actual group name here
            }
        }
    
        const newMeeting = {
            title,
            start: slot.start,
            end: slot.end,
            notes,
            attendees: [currentUser.uid, professorId, ...selectedUsers],
            professorId,
            timeSlotGroupId: timeSlotGroup.id,
            slotId: slot.id,  // Include the slotId to reference this specific slot
            groupName,
        };
    
        try {
            // Add meeting to student's meetings collection and get the document reference
            console.log("Adding meeting to student's meetings collection...");
            const studentMeetingDocRef = await addDoc(
                collection(db, "users", currentUser.uid, "meetings"),
                newMeeting
            );

            // Update the newMeeting object with the generated document ID
            const updatedMeeting = {
                ...newMeeting,
                meetingId: studentMeetingDocRef.id, // Use this ID for both student and professor meeting records
            };

            // Save the updated meeting with ID in both student's and professor's collections
            await setDoc(studentMeetingDocRef, updatedMeeting);
            await setDoc(
                doc(db, "users", professorId, "meetings", studentMeetingDocRef.id),
                updatedMeeting
            );

             // Remove the creator from selectedUsers to avoid saving the meeting twice
            const filteredAttendees = selectedUsers.filter(userId => userId !== currentUser.uid);

            // Add meeting for each selected user (attendees excluding the creator)
            for (const userId of filteredAttendees) {
                console.log(`Adding meeting to attendee's meetings collection for userId: ${userId}`);
                const userMeetingRef = doc(db, "users", userId, "meetings", studentMeetingDocRef.id);
                await setDoc(userMeetingRef, updatedMeeting);
            }

    
            // Update the slot to indicate it's no longer available
            const updatedSlots = timeSlotGroup.slots.map((s) =>
                s.id === slot.id ? { ...s, available: false } : s
            );
            
            const updatedSlotsCount = timeSlotGroup.slotsCount - 1;

            // Update the time slot group in Firestore
            console.log("Updating time slot group in Firestore...");
            await setDoc(doc(db, "users", professorId, "timeSlotGroups", timeSlotGroup.id), {
                ...timeSlotGroup,
                slots: updatedSlots,
                slotsCount: updatedSlotsCount,
            });
    
            // Update the state to reflect the change
            setTimeSlotGroup({ ...timeSlotGroup, slots: updatedSlots, slotsCount: updatedSlotsCount });
    
            alert("Time slot booked successfully!");
        } catch (error) {
            console.error("Error booking time slot:", error);
            alert("Failed to book time slot.");
        }
    };

    return (
        <div className="time-slots-container">
            <div className="time-slots-box">
            <h2>Select Time Slot</h2>
            <div className="time-slots-list">
                {timeSlotGroup ? (
                    timeSlotGroup.slots.length > 0 ? (
                        timeSlotGroup.slots.map((slot) => (
                            <button
                                key={slot.start}
                                onClick={() => handleBookSlot(slot)}
                                className={`time-slot-button ${slot.available ? "available" : "booked"}`}
                                disabled={!slot.available}
                            >
                                {formatTime(slot.start)} - {formatTime(slot.end)}
                            </button>
                        ))
                    ) : (
                        <p>No available slots for this day.</p>
                    )
                ) : (
                    <p>Loading available slots...</p>
                )}
            </div>
            <div className="booking-details">
                <h3>Set Meeting Details</h3>
                <div className="form-group">
                    <label>Title:</label>
                    <input
                        type="text"
                        className="form-control"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder="Meeting Title"
                    />
                </div>
                <div className="form-group">
                    <label>Notes:</label>
                    <textarea
                        className="form-control"
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                        placeholder="e.g. Zoom link or any important notes"
                    />
                </div>
                <div className="form-group">
                    <label>Group (optional):</label>
                    <select
                        value={selectedGroup}
                        onChange={(e) => {
                            const groupId = e.target.value;
                            setSelectedGroup(groupId);
                            if (groupId) {
                                const selectedGroupObj = groups.find(group => group.id === groupId);
                                    if (selectedGroupObj) {
                                        setSelectedUsers([...new Set([...selectedUsers, ...selectedGroupObj.members])]);
                                    }
                            }
                        }}
                        className="form-control"
                        >
                        <option value="">Select a group</option>
                        {groups
                            .map((group) => (
                                <option key={group.id} value={group.id}>
                                    {group.name}
                                </option>
                        ))}
                    </select>
                </div>
            </div>
            <button onClick={() => navigate(-1)} className="btn btn-back">
                Back to Calendar
            </button>
            </div>
        </div>
    );
};

export default TimeSlotsPage;
