import React, { useEffect, useState } from "react";
import profilePic from "./images/ProfilePic1.jpg";

//Create Profile Lab for Advisors

function ProfileLabAdvisorTab() {
	const [advUserName, setAdvUserName] = useState([]);
	const [advEmail, setAdvEmail] = useState([]);
	const [advProfilePic, setAdvProfilePic] = useState([]);
	//get you lab advisor information

	//temporarily set the lab advisor information
	useEffect(() => {
		setAdvUserName("labAdvisor1");
		setAdvEmail("Advisor1@csus.edu");
		setAdvProfilePic(profilePic);
	}, []);

	//TODO: query the database for lab advisor
	/* 	const fetchAdvisorData = async () => {
		try {
			const response = await fetch("your_api_endpoint_here");
			const data = await response.json();
			setAdvUserName(data.username);
			setAdvEmail(data.email);
			setAdvProfilePic(data.profilePic);
		} catch (error) {
			console.error("Error fetching advisor data:", error);
		}
	};

	useEffect(() => {fetchAdvisorData();	}, []); */

	//render - will need to setup to render an array of lab advisors
	return htmAdvisor(advUserName, advEmail, advProfilePic);
}

function htmAdvisor(name, email, profilePic) {
	return (
		<div>
			<h1>Lab Advisor</h1>
			<label>
				Username:
				{name}
			</label>
			<br />
			<label>
				Email:
				{email}
			</label>
			<br />
			<label>
				Profile Picture
				<br />
				<img
					src={profilePic}
					alt=""
					style={{ width: "150px", height: "150px" }}
				/>
			</label>
			<br />
		</div>
	);
}

export default ProfileLabAdvisorTab;
