import React, { useState, useEffect } from "react";

/**
 * The URL endpoint for fetching classes data.
 * @constant {string}
 */
const classesUrl = "/api/classes"; //TODO: replace with actual API endpoint

/**
 * AddClassFromList component allows users to select a class from a list and add it to their profile.
 * 
 * @component
 * @example
 * return (
 *   <AddClassFromList />
 * )
 * 
 * @returns {JSX.Element} The rendered component.
 * 
 * @description
 * This component fetches a list of classes from an API and displays them in a dropdown menu.
 * Users can select a class and add it to their profile by clicking the "Add Class" button.
 * 
 * @function
 * @name AddClassFromList
 * 
 * @property {Array} classes - The list of classes fetched from the API.
 * @property {Function} setClasses - Function to update the list of classes.
 * @property {string} selectedClass - The class selected by the user.
 * @property {Function} setSelectedClass - Function to update the selected class.
 * 
 * @hook
 * @name useState
 * @description Manages the state of the classes and the selected class.
 * 
 * @hook
 * @name useEffect
 * @description Fetches the list of classes from the API when the component mounts.
 * 
 * @function
 * @name handleSelectChange
 * @description Updates the selected class when the user selects a different class from the dropdown.
 * 
 * @function
 * @name handleAddClass
 * @description Adds the selected class to the user's profile by making a POST request to the API.
 * 
 * @throws Will throw an error if the fetch request to add the class fails.
 */
function AddClassFromList() {
	const [classes, setClasses] = useState([]);
	const [selectedClass, setSelectedClass] = useState("");

	useEffect(() => {
		const fetchClasses = async () => {
			try {
				const response = await fetch(classesUrl);
				const data = await response.json();
				setClasses(data);
			} catch (error) {
				console.error("Error fetching classes:", error);
			}
		};

		fetchClasses();
	}, []);

	const handleSelectChange = (event) => {
		setSelectedClass(event.target.value);
	};

	const handleAddClass = () => {
		const studentId = localStorage.getItem("studentId");
		if (!studentId) {
			alert("Student ID not found in local storage");
			return;
		}
		const addClassUrl = `/api/students/${studentId}/addClass`; //TODO: replace with actual API endpoint

		const addClassToDatabase = async () => {
			try {
				const response = await fetch(addClassUrl, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ classId: selectedClass }),
				});

				if (!response.ok) {
					throw new Error("Failed to add class");
				}

				const result = await response.json();
				console.log("Class added successfully:", result);
				alert("Class added successfully");
				window.location.reload();
			} catch (error) {
				console.error("Error adding class:", error);
				alert("Failed to add class");
			}
		};

		addClassToDatabase();
	};

	//fake classes data to test with. Remove this when the API is ready
	if (classes.length === 0) {
		setClasses([
			{ id: "1", name: "Math 101" },
			{ id: "2", name: "History 201" },
			{ id: "3", name: "Science 301" },
		]);
	}

	// Check if student ID is missing and create a fake one for testing. Remove this when the API is ready
	if (!localStorage.getItem("studentId")) {
		localStorage.setItem("studentId", "fake-student-id-123");
	}

	return (
		<div>
			<label htmlFor="class-select">Select a class:</label>
			<select
				id="class-select"
				value={selectedClass}
				onChange={handleSelectChange}>
				<option value="">--Please choose an option--</option>
				{classes.map((classItem) => (
					<option key={classItem.id} value={classItem.id}>
						{classItem.name}
					</option>
				))}
			</select>

			<button onClick={handleAddClass}>Add Class</button>
		</div>
	);
}

export default AddClassFromList;
