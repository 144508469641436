import "./pageHeader.css";
import React from "react";

const PageHeader = () => {
	return (
		<header>
			<div
				id="logoBox"
				onClick={() => (window.location.href = "https://csus.edu")}
				style={{ cursor: "pointer" }}></div>
		</header>
	);
};
export default PageHeader;
