import React, { useState, useContext } from 'react';
import { getAuth, signInWithEmailAndPassword, setPersistence, browserSessionPersistence } from "firebase/auth";
import { useNavigate, Link } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../Firebase/firebase";
import "./CSS/BaseWebsite.css"; 
import { UserContext } from '../UserContext';

function LoginPortal() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate(); 

    const { setCurrentUser, setRole } = useContext(UserContext); 

    const handleLogin = async (e) => {
        e.preventDefault(); 
        try {
            console.log("Starting login process...");
            const auth = getAuth(); 
            
            // If browser closed need to re-authenticate
            await setPersistence(auth, browserSessionPersistence);
            console.log("Persistence set to browser session.");
    
            // Firebase function to login with email and password
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user; 
            console.log("Sign-in successful, checking email verification...");

            // Email verification check
            if (user && !user.emailVerified) {
                setError("Please verify your email before logging in. Check your inbox for the verification email.");
                console.log("User email is not verified.");
                return; // Exit the function here if the email is not verified
            }

            console.log("User email is verified.");
            const userDocRef = doc(db, 'users', user.uid);
            const userDoc = await getDoc(userDocRef);
    
            if (userDoc.exists()) {
                const userData = userDoc.data();
    
                // Update UserContext with current user and role
                setCurrentUser(user);
                setRole(userData.role);
                console.log("User role found:", userData.role);
    
                // Navigate based on role
                navigate("/userPage");
            }
    
        } catch (error) {
            setError("Login failed. Please check your credentials and try again.");
            console.error("Login Error: ", error);
        }
    };

    return (
        <div>
            <div id="loginForm" style={{ textAlign: "center" }}>
                <h2>Login</h2>
                {error && <p style={{ color: 'red' }}>{error}</p>} 

                <form onSubmit={handleLogin}>
                    <div className="form-group">
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)} 
                            required
                            placeholder="Enter your email"
                        />
                    </div>

                    <div className="form-group">
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            placeholder="Enter your password"
                        />
                    </div>

                    <button type="submit">Login</button>
                </form>
                
                <div>
                    <p>
                        <Link to="/createAccount.js">Create Account</Link>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default LoginPortal;
