/**
 * Retrieves the username from the browser's cookies.
 *
 * This function searches through the document's cookies to find a cookie
 * with the name "username" and returns its value. If no such cookie is found,
 * it returns an empty string.
 *
 * @returns {string} The username from the cookie, or an empty string if not found.
 */
function getUserNameFromCookie() {
    const name = "username=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    for(let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i].trim();
        if (cookie.indexOf(name) === 0) {
            return cookie.substring(name.length, cookie.length);
        }
    }
    return "";
}

// Example usage
const username = getUserNameFromCookie();
console.log(username);