import React, { useEffect, useState } from "react";
import profilePic from "./images/ProfilePic2.jpg";
import ProfilePictureMenu from "./ProfilePicture";
import { db } from "./Firebase/firebase";
import {
	collection,
	setDoc,
	addDoc,
	getDocs,
	deleteDoc,
	doc,
} from "firebase/firestore";

const defaultProfilePicture = require("./images/default_profile_pic.jpg");

/**
 * Profile Component to the Profile Tab list, will show outsiders the users base information
 * ie username, profile pic, email
 *
 * @returns {JSX.Element} Profile component
 */

const UserProfile = () => {
	const [username, setUsername] = useState("");
	const [email, setEmail] = useState("");
	const [profilePicture, setProfilePicture] = useState("");

	//temp profile picture
	useEffect(() => {
		setProfilePicture(profilePic);
	}, []);

	//fetch user from database
	useEffect(() => {
		const getUser = async () => {
			const data = await getDocs(collection(db, "users"));
			data.forEach((doc) => {
				setUsername(doc.data().username);
				setEmail(doc.data().email);
				setProfilePicture(doc.data().profilePicture);
			});
		};
		getUser();
	}, []);

	const handleSave = async () => {
		try {
			const userDoc = doc(db, "users", "userId"); // replace "userId" with the actual user id

			// Ensure profilePicture is defined
			const profileData = {
				username,
				email,
				profilePicture: profilePicture || "", // Default to an empty string if undefined
			};

			await setDoc(userDoc, profileData);
			alert("Profile saved successfully!");
		} catch (error) {
			console.error("Error saving profile: ", error);
			alert("Failed to save profile.");
		}
	};

	return (
		<div id="Profile">
			<h1>Profile</h1>
			<div>Username: </div>
			<input
				type="text"
				value={username}
				onChange={(e) => setUsername(e.target.value)}
				placeholder="Enter username"
			/>
			<br />
			<div>Email: </div>
			<input
				type="email"
				value={email}
				onChange={(e) => setEmail(e.target.value)}
				placeholder="Enter email"
			/>
			<br />
			<label id="ProfilePicture">
				<br />
				<ProfilePictureMenu></ProfilePictureMenu>
			</label>
			<br />
			<button onClick={handleSave}>Save</button>
		</div>
	);
};

export default UserProfile;
