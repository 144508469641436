import React, { useState } from "react";
import ProfilePictureMenu from "./ProfilePicture";

/**
 * UserSettings component represents the user settings form.
 * It allows the user to update their username, first name, last name, and email.
 *
 * @returns {JSX.Element} The rendered UserSettings component.
 */
const UserSettings = () => {
    //TODO: initialize values from the database
    const [username, setUsername] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [currentEmail] = useState("");
    const [newEmail, setNewEmail] = useState("");
    const [showPasswordChange, setShowPasswordChange] = useState(false);
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    
    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };

    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value);
    };

    const handleLastNameChange = (event) => {
        setLastName(event.target.value);
    };

    const handleEmailChange = (event) => {
        setNewEmail(event.target.value);
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const isValidPassword = (password) => {
        // Example: Minimum eight characters, at least one letter and one number
        const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
        return passwordRegex.test(password);
    };

    const handlePasswordChange = () => {
        if (isValidPassword(newPassword) && oldPassword === "correct_old_password") {
            console.log("Password changed successfully");
            // Update the password in the database or state
        } else {
            console.log("Password change failed");
        }
    };

    const togglePasswordChange = () => {
        setShowPasswordChange(!showPasswordChange);
    };

    const handleSave = () => {
        console.log("username: ", username);
        console.log("first name: ", firstName);
        console.log("last name: ", lastName);
        console.log("new email: ", newEmail);

        if (!isValidEmail(newEmail)) {
            console.log("Invalid email address");

        }
    };

    const handleProfilePictureSubmitted = (file) => {
        console.log("Received profile picture for upload:", file);
    };

    return (
        <div>
            <h1>User Settings</h1>
            <label>
                Username:
                <input type="text" value={username} onChange={handleUsernameChange} />
            </label>
            <br />
            <label>
                First Name:
                <input type="text" value={firstName} onChange={handleFirstNameChange} />
            </label>
            <br />
            <label>
                Last Name:
                <input type="text" value={lastName} onChange={handleLastNameChange} />
            </label>
            <br />
            <label>
                Current Email:
                <input type="text" value={currentEmail} disabled />
            </label>{" "}
            <br />
            <label>
                New Email:
                <input type="email" value={newEmail} onChange={handleEmailChange} />

            </label>
            
            {newEmail && !isValidEmail(newEmail) && <p style={{ color: "red" }}>Invalid email address</p>}
            <br />

            <button type="button" onClick={handleSave}>
                Save
            </button>

            <br />
            <label>
                Change Password
            </label>
            <br />
            <button onClick={togglePasswordChange}>Change</button>
            {showPasswordChange && (
                <>
                    <input
                        type="password"
                        placeholder="Old Password"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                    />
                    <input
                        type="password"
                        placeholder="New Password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <button onClick={handlePasswordChange}>Submit</button>
                </>
            )}
            <br />
            <ProfilePictureMenu onSubmit={handleProfilePictureSubmitted} />
        </div>
    );
};

export default UserSettings;
