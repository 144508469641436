import React from 'react';
//TODO: Import the 'firebase' module
//import firebase from 'firebase'; // Import the 'firebase' module

/**
 * UserMessages component displays user messages fetched from the database.
 * @returns {JSX.Element} The rendered UserMessages component.
 */
const UserMessages = () => {
    //TODO: Fetch messages from the database
    //TODO: Display messages in the UI

    const [messages, setMessages] = React.useState([]); // State to store messages

    // Fetch messages from the database
    React.useEffect(() => {
        // TODO: Implement code to fetch messages from the Firebase database
        // Example code:
        const fetchMessages = async () => {
        //TODO: uncomment code after the 'firebase' module is imported
           /*  try {
                const response = await firebase.firestore().collection('messages').get();
                const fetchedMessages = response.docs.map(doc => doc.data());
                setMessages(fetchedMessages);
            } catch (error) {
                console.error('Error fetching messages:', error);
            } */
        };

        fetchMessages();
    }, []);

    // Display messages in the UI
    return (
        <div>
            <h1>User Messages</h1>
            {messages.length === 0 ? (
                <p>No messages to display</p>
            ) : (
                <ul>
                    {messages.map((message, index) => (
                        <li key={index}>{message}</li>
                    ))}
                </ul>
            )}
        </div>
    );

};

export default UserMessages;