import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyAHvTtKiArun_vJr6uVgQ9QaFFivypwJ_o",
    authDomain: "labadvisorplusplusinternaltest.firebaseapp.com",
    projectId: "labadvisorplusplusinternaltest",
    storageBucket: "labadvisorplusplusinternaltest.appspot.com",
    messagingSenderId: "774821619922",
    appId: "1:774821619922:web:665cbad8cc8af5f6db250b",
    measurementId: "G-5VE5BXYN1X"
};

export const app = initializeApp(firebaseConfig); // Initialize Firebase.
export const auth = getAuth(app);

const analytics = getAnalytics(app);
export const url = "https://labadvisorplusplusinternaltest-default-rtdb.firebaseio.com/";
export const db = getFirestore(app);

export const storage = getStorage(app);

const messaging = getMessaging(app);
getToken(messaging, { vapidKey: 'BLw4fTx46ca0R6WZSinE0utalER5LifhodplcvvS_r1wGpK_QehvJCu8RxOUwxrLmVUFPA4_C3Fzobvdcly5s98' }).then((currentToken) => {
    if (currentToken) {
        // Handle the current token
    } else {
        console.log("No registration available. Request permission to generate one.");
    }
}).catch((err) => {
    console.log("An error has occurred while retrieving token.", err);
});

onMessage(messaging, (payload) => {
    console.log("Message received. ", payload);
});