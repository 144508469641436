import React, { createContext, useState, useEffect } from "react";
import { auth, db } from "./Firebase/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { getDoc, doc } from "firebase/firestore";

export const UserContext = createContext();

// UserContext allows us to store current user info and wrap around entire app
// so each component doesn't need to fetch user role separately making it
// easily accessible throughout application and allows for role based rendering.
// If component requires user info ie. their role use "useContext"

export const UserProvider = ({children}) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [role, setRole] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                console.log("User found:", user);
                let userInfo = {
                    uid: user.uid,
                    email: user.email,
                };
                //setCurrentUser(user);
                try {
                    // Gets the user document depending on which ID
                    const userDocRef = doc(db, "users", user.uid);
                    const userDoc = await getDoc(userDocRef);
          
                    if (userDoc.exists()) {
                        const userData = userDoc.data();
                        console.log("User role found:", userData.role);
                        setRole(userData.role);
                        // Gets users name
                        userInfo = {
                            ...userInfo,
                            firstName: userData.firstName || "NoFirstName",
                            lastName: userData.lastName || "NoLastName",
                            username: userData.username || "Default Username",
                        };
                    } else {
                        console.warn("User document not found");
                    }
                }
                catch (error) {
                    console.error("Error fetching user data:", error);
                }
                setCurrentUser(userInfo);
            } else {
                console.log("No user found");
                setCurrentUser(null);
                setRole("");
            }
            setLoading(false);
        });
        return() => unsubscribe();
    }, []);

    return (
        <UserContext.Provider value={{ currentUser, setCurrentUser, role, setRole, loading }}>
            {!loading && children}
        </UserContext.Provider>
    );
}