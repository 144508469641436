import React, { useState, useEffect, useContext } from "react";
import { db } from "../Firebase/firebase";
import {
	collection,
	addDoc,
	getDocs,
	getDoc,
	updateDoc,
	doc,
	deleteDoc,
	arrayRemove,
	documentId,
	arrayUnion,
	query,
	where,
} from "firebase/firestore";
import { UserContext } from "../UserContext";
import "./GroupManager.css";
import { getAuth } from "firebase/auth";
import styled from "styled-components";
import PageHeader from "../Webpages/PageHeader";

const GroupManager = () => {
	const [groupName, setGroupName] = useState("");
	const [students, setStudents] = useState([]);
	const [selectedStudent, setSelectedStudent] = useState("");
	const [groupMembers, setGroupMembers] = useState([]);
	const [groups, setGroups] = useState([]);

	const { currentUser, loading } = useContext(UserContext);

	const auth = getAuth();
	const user = auth.currentUser;

	// Fetch students and groups from the database
	useEffect(() => {
		const fetchStudents = async () => {
			try {
				// Fetches students
				const studentsData = await getDocs(collection(db, "users"));
				setStudents(
					studentsData.docs
						.map((doc) => ({ uid: doc.id, ...doc.data() }))
						.filter((student) => student.uid !== currentUser?.uid)
				);
			} catch (error) {
				console.error("Error fetching students:", error);
			}
		};

		const fetchGroups = async () => {
			try {
				// Fetches groups
				const groupsData = await getDocs(collection(db, "groups"));
				setGroups(
					groupsData.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
				);
			} catch (error) {
				console.error("Error fetching groups:", error);
			}
		};

		fetchStudents();
		fetchGroups();
	}, [currentUser]);

	// Handle adding a user to the team
	const handleAddMember = () => {
		if (!selectedStudent) {
			alert("Please select a student to add.");
			return;
		}

		if (groupMembers.includes(selectedStudent)) {
			alert("This student is already in the team.");
			return;
		}

		setGroupMembers([...groupMembers, selectedStudent]);
		setSelectedStudent("");
	};

	// Handles removing user from team
	const handleRemoveMember = (memberId) => {
		setGroupMembers(groupMembers.filter((member) => member !== memberId));
	};

	// Handles group creation
	const handleCreateGroup = async () => {
		if (!groupName) {
			alert("Team name is required!");
			return;
		}

		if (loading) {
			alert("Please wait until loading is complete.");
			return;
		}

		if (!currentUser || !currentUser.uid) {
			alert("You must be logged in to create a team.");
			return;
		}

		// Check if any of the selected members (including the current user) are already in a group
		const allMembers = [...new Set([currentUser.uid, ...groupMembers])];
		const isAlreadyInGroup = allMembers.some((memberId) =>
			groups.some((group) => group.members.includes(memberId))
		);

		if (isAlreadyInGroup) {
			alert(
				"One or more of the selected members are already part of an existing team."
			);
			return;
		}

		try {
			const newGroup = {
				name: groupName,
				members: allMembers,
				creator: currentUser.uid,
				advisor: "",
			};
			await addDoc(collection(db, "groups"), newGroup);
			alert("Team created successfully!");
			setGroups([...groups, newGroup]);
			setGroupName("");
			setGroupMembers([]);
		} catch (error) {
			console.error("Error creating team:", error);
			alert("Failed to create team. Please try again.");
		}
	};

	// Handles leaving group
	const handleLeaveGroup = async (groupId) => {
		try {
			const groupRef = doc(db, "groups", groupId);
			const groupDoc = await getDoc(groupRef);

			if (groupDoc.exists()) {
				const groupData = groupDoc.data();
				const updatedMembers = groupData.members.filter(
					(memberId) => memberId !== currentUser.uid
				);
				// Update team list in database
				await updateDoc(groupRef, { members: updatedMembers });
				setGroups(
					groups.map((group) =>
						group.id === groupId ? { ...group, members: updatedMembers } : group
					)
				);

				alert("You have left the team.");
			}
		} catch (error) {
			console.error("Error leaving team:", error);
			alert("Failed to leave the team. Please try again.");
		}
	};

	const deleteTeam = async (groupId) => {
		try {
			const groupRef = doc(db, "groups", groupId);
			console.log("Got Doc:", groupRef);
			await deleteDoc(groupRef);
			alert("Team Successfully Deleted");
		} catch {
			console.error("Error Deleting Doc");
			alert("Failed to delete team");
		}
	};

	const handleRemoveGroupMember = async (memberId, groupId) => {
		try {
			const groupRef = doc(db, "groups", groupId);
			console.log("Got Doc:", groupRef);
			await updateDoc(groupRef, {
				members: arrayRemove(memberId),
			});
			console.log("Successfully removed");
			alert("Removed Group Member");
		} catch (error) {
			console.error("Failed to remove member", error);
			alert("Failed to remove member");
		}
	};

	const getAdvisorName = (advisorId) => {
		try {
			const groupAdvisor = students.find(
				(advisor) => advisor.uid === advisorId
			);
			return `${groupAdvisor.firstName} ${groupAdvisor.lastName}`;
		} catch (error) {
			console.error("Error reading advisor", error);
			return "";
		}
	};

	return (
		<>
			{user && (
				<div>
					{" "}
					<PageHeader />
					<div className="group-manager-container">
						<div className="group-creation-box">
							<h2 className="group-manager-header">Create Team</h2>
							<div className="group-manager-form">
								<input
									type="text"
									value={groupName}
									onChange={(e) => setGroupName(e.target.value)}
									placeholder="Enter team name"
									className="group-input"
								/>
								<div className="form-group">
									<label className="group-label">Add Student to Team:</label>
									<div className="form-inline">
										<select
											value={selectedStudent}
											onChange={(e) => setSelectedStudent(e.target.value)}
											className="group-select">
											<option value="">Select a student</option>
											{students.map((student) => (
												<option key={student.uid} value={student.uid}>
													{student.firstName} {student.lastName}
												</option>
											))}
										</select>
										<button
											onClick={handleAddMember}
											className="btn-add-member">
											Add Member
										</button>
									</div>
								</div>
							</div>

							<div className="group-members">
								<h3 className="group-members-header">Team Members</h3>
								<ul className="group-members-list">
									{[...groupMembers, currentUser.uid].map((memberId, index) => {
										const student = students.find(
											(student) => student.uid === memberId
										);
										const isLastMember = index === groupMembers.length;

										if (student) {
											return (
												<li key={memberId} className="group-member-item">
													<span
														className="remove-member"
														onClick={() => handleRemoveMember(memberId)}>
														&times;
													</span>
													{student.firstName} {student.lastName}
													{!isLastMember && ", "}
												</li>
											);
										} else if (memberId === currentUser.uid) {
											return (
												<li
													key={currentUser.uid}
													className="group-member-item current-user">
													{currentUser.firstName} {currentUser.lastName} (You)
													{!isLastMember && ", "}
												</li>
											);
										}
										return null;
									})}
								</ul>
							</div>

							<button onClick={handleCreateGroup} className="btn-create-group">
								Create Team
							</button>
						</div>

						<TeamInvitePopup></TeamInvitePopup>

						{/* Group list user is in */}
						<div className="your-groups-box">
							<h2 className="existing-groups-header">Your Team</h2>
							<ul className="existing-groups-list">
								{groups
									.filter((group) => group.members.includes(currentUser.uid))
									.map((group) => (
										<li key={group.id} className="group-item">
											<div className="group-info">
												<button
													onClick={() => handleLeaveGroup(group.id)}
													className="btn-leave-group">
													Leave Team
												</button>
												<br />
												<button onClick={() => deleteTeam(group.id)}>
													Delete Team
												</button>
												<strong className="group-name">{group.name}</strong> (
												{group.members.length} members)
												<br />
												<strong>Join Code: {group.id}</strong>
												<br />
												<h4>Group Members</h4>
												<span className="group-members">
													{group.members
														.map((memberId) => {
															const member =
																students.find(
																	(student) => student.uid === memberId
																) || currentUser;
															return (
																<>
																	<li>
																		{member
																			? `${member.firstName} ${member.lastName}`
																			: null}
																	</li>
																	{member.uid !== currentUser.uid && (
																		<button
																			onClick={(e) =>
																				handleRemoveGroupMember(
																					member.uid,
																					group.id
																				)
																			}>
																			Remove
																		</button>
																	)}
																	<br />
																</>
															);
														})
														.filter((name) => name !== null)}
													{group.advisor !== "" ? (
														<h4>Advisor: {getAdvisorName(group.advisor)}</h4>
													) : (
														<h4>Advisor: N/A</h4>
													)}
												</span>
											</div>
										</li>
									))}
							</ul>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default GroupManager;

function TeamInvitePopup() {
	const [inputFieldData, setInputFieldData] = useState("");

	const [popupState, setPopupState] = useState(false);

	function togglePopup() {
		setPopupState(!popupState);
	}

	const handleSubmit = async (event) => {
		event.preventDefault();

		console.log("Handling Submit");
		await handleGroupCodeDatabase(inputFieldData);
	};

	const handleChange = (event) => {
		setInputFieldData(event.target.value);
	};

	const { role } = useContext(UserContext);
	const auth = getAuth();
	const user = auth.currentUser;

	async function handleGroupCodeDatabase(groupCode) {
		const groupsRef = collection(db, "groups");
		const q = query(groupsRef, where(documentId(), "==", groupCode));
		console.log(q);
		let docQ = null;

		await getDocs(q)
			.then((querySnapshot) => {
				if (!querySnapshot.empty) {
					docQ = querySnapshot.docs[0];
					console.log("Document data:", docQ.data());
					addToGroup(docQ);
				} else {
					console.log("Document does not exist");
					alert("Group Code Invalid");
				}
			})
			.catch((error) => {
				console.error("Error retrieving document:", error);
			});

		async function addToGroup(docQ) {
			let isValidTeamCode = true;
			if (docQ != null) {
				console.log("Doc exists, commencing add");
			} else {
				console.log("No document, cannot add to team");
				isValidTeamCode = false;
			}

			if (isValidTeamCode) {
				console.log("Valid Group Code");
				const docRef = doc(db, "groups", docQ.id);
				console.log("user role", role);

				try {
					if (role === "Student") {
						console.log("User is a student, adding");
						await updateDoc(docRef, {
							members: arrayUnion(user.uid),
						});
					} else if (role === "Professor") {
						await updateDoc(docRef, {
							advisor: user.uid,
						});
					}
				} catch (error) {
					console.error("Failed to update doc: ", error);
					alert("Failed to join Group");
				}
			}
		}
	}

	return (
		<div>
			<button onClick={togglePopup} id="openPopupButton">Join Group Via Join Code</button>
			{popupState && (
				<div id="popupContainer">
					<div id="popupInnerContainer">
						<button onClick={togglePopup} id="closePopupButton">Close</button>
						<h1 id="teamJoinHeader">Group Join</h1>
						<h4 id="teamJoinContent">To join a team, enter the team join code in the box below.</h4>
						<div id="joinInputForm">
							<form>
								<label>
									<input
										type="text"
										name="joinCode"
										pattern="[A-Za-z0-9]+"
										minLength={20}
										maxLength={20}
										value={inputFieldData}
										onChange={handleChange}
										placeholder="Join Code"
										id="joinInput"
									/>
								</label>
								<button type="submit" onClick={handleSubmit} id="joinButton">
									Join
								</button>
							</form>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
