import React, {useState, useContext} from "react";
import "./homepage.css";
import { getAuth, setPersistence, signInWithEmailAndPassword, browserSessionPersistence } from "firebase/auth";
import {validateField, emailExists} from './inputValidation';
import usernameInput from "./AssetsLoginSignup/usernameInput";
import { useNavigate } from "react-router-dom";
import { UserContext } from "./UserContext";
import { db } from "./Firebase/firebase";
import { doc, getDoc } from "firebase/firestore";
import PageHeader from "./Webpages/PageHeader";

function LoginPortal() {

	const { setCurrentUser, setRole } = useContext(UserContext);
	const auth = getAuth();
  	const navigate = useNavigate();

	//code to check for login credentials and moving to profile page
	const [inputFieldData, setInputFieldData] = useState({  // Holds the inputs
        email: '',
		password: ''
    });

	const [inputFieldErrors, SetInputFieldErrors] = useState({}); // Store any field errors

	const handleChange = (event) => { // Function updates the new/current inputs
        const { name, value } = event.target;
        setInputFieldData(prev => ({ ...prev, [name]: value }));
    };

	const handleSubmit = async (event) => { // Function handles the submission to check if its valid
        event.preventDefault();
		let errors = {};
        let isFormValid = true;

		Object.keys(inputFieldData).forEach(key => { //Goes over inputs to check if filled
			if (!inputFieldData[key]) {
			  	errors[key] = '*This field is required';
			  	isFormValid = false;
			} 
			else { // Checks if validateField was good
			  	const fieldError = validateField(key, inputFieldData[key]);
			  	if (fieldError) {
					errors[key] = fieldError;
					isFormValid = false;
			  	} 
			}
		});

		// Check email exists
        if (!(await emailExists(inputFieldData.email))) {
			errors.email = '*Email Does Not Exist';
			isFormValid = false;
		}

		SetInputFieldErrors(errors);

        if (isFormValid) {
            console.log("Form is valid", inputFieldData);

            //login here
			try {
				// Existing and future Auth states are now persisted in the current
    			// session only. Closing the window would clear any existing state even
    			// if a user forgets to sign out.
    			// ...
    			// New sign-in will be persisted with session persistence.
				await setPersistence(auth, browserSessionPersistence)
				const userCredential = await signInWithEmailAndPassword(auth, inputFieldData.email, inputFieldData.password)
				const user = userCredential.user;

				if (!user.emailVerified) {
					SetInputFieldErrors({ ...errors, email: "Please verify your email before logging in." });
                	console.log("User email is not verified.");
                	return;
				}

				// Fetch user data from Firestore
				const userDocRef = doc(db, 'users', user.uid);
				const userDoc = await getDoc(userDocRef);

				if (userDoc.exists()) {
					const userData = userDoc.data();
	
					// Set UserContext with current user and role
					setCurrentUser(user);
					setRole(userData.role);
					console.log("User role found:", userData.role);
	
					// Navigate based on role
					navigate("/Calendar");
				}

			}catch (error) {
					console.error("Login Error: ", error);
					SetInputFieldErrors({ ...errors, email: "Incorrect Email or Password" });
			}

		}
		else {
			console.error("No user data found in Firestore.");
			document.getElementById("loginError").style.display = "block";
		}
	}

	return (
		<div>
		<div>
		<PageHeader />	

			<form id="loginForm">
				<div id="loginContents" style={{ textAlign: "center" }}>

					<div>
						<label> <b>Email:  </b>
							<input
								type="email"
								name="email"
								maxLength={40}
								value={inputFieldData.email}
								onChange={handleChange}
								placeholder="email@csus.edu"
							/>
						</label>
						<div></div>
						<br />
						<label> <b>Password:  </b>
							<input
								type="password"
								name="password"
								minLength={8}
								maxLength={16}
								value={inputFieldData.password}
								onChange={handleChange}
								placeholder="Password"
								required
							/>
						</label>
						<div></div>
						<div id="loginError">
							<label>Email/Password Incorrect</label></div>
						<div></div>
						<br />
						<button 
							onClick={handleSubmit}
							type="submit">Login</button>
						<br />
					</div>
				</div>
				<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
					<button 
						type="button"
						onClick={() => navigate("/CreateAccount")}
						style={{ textAlign: "left" }}>
						Create Account
					</button>
					<button 
						type="button"
						onClick={() => navigate("/ForgotPassword")}
						style={{ textAlign: "right" }}> 
						Forgot Password?
					</button>
					</div>
			</form>
		</div>
		</div>
	);
}

export default LoginPortal;