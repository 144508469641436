import React, { useState } from "react";
import { storage, db, auth } from "./Firebase/firebase"; // Import Firebase services
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { doc, setDoc } from "firebase/firestore";


function ProfilePictureMenu() {
	const [profilePicture, setProfilePicture] = useState(null);

    // Upload function
    const handlePictureUpload = (event) => {
        const file = event.target.files[0];

		if (file) {
			const reader = new FileReader();

            reader.onload = function (e) {
                const result = e.target.result; // This is automatically a string when using readAsDataURL

                const img = new Image();
                img.src = result; // This will work since result is a string

                img.onload = function () {
                    const maxWidth = 800;
                    const maxHeight = 800;

                    if (img.width > maxWidth || img.height > maxHeight) {
                        alert(`Image dimensions exceed the limit: (${maxWidth}x${maxHeight} px). Please upload an image that meets these requirements.`);
                        return;
                    }

                    setProfilePicture(file); // Set the selected file to state
                };
            };

            reader.readAsDataURL(file);
        }
    };

    // Submission function
    const handleSubmit = async () => {
        console.log("Current user:", auth.currentUser);
        if (!profilePicture) return alert("Please select a picture first.");

        const user = auth.currentUser; // Get current logged-in user

        if (user) {
            const storage = getStorage(); // Get storage instance
            const storageRef = ref(storage, `profilePictures/${user.uid}/${profilePicture.name}`); // Create a reference

            try {
                // Upload the file to Firebase Storage
                await uploadBytes(storageRef, profilePicture);

                // Get the image URL
                const downloadURL = await getDownloadURL(storageRef);

                // Use Firestore's modular syntax to update the image URL

                const userDocRef = doc(db, "users", user.uid); // Reference to the user document

                // Update Firestore with the image URL
                await setDoc(userDocRef, { profilePicture: downloadURL }, { merge: true });

                alert("Profile picture updated successfully!");

            } catch (error) {
                console.error("Error uploading profile picture:", error);
            }
        }
    };

    //*****NEED TO DELETE PREVIOUS PROFILE PICTURE, CAN DO THIS BY READING OLD FIELD, THEN DELETING THE OLD ONE VIA FILEPATH IF IT EXISTS

    // JSX user interface
    return (
        <div>
            <label>Profile Picture</label>
            <br />
            {profilePicture && (
                <div>
                    <img src={URL.createObjectURL(profilePicture)} alt="Profile" style={{ width: "100px", height: "100px", objectFit: "cover" }} />
                </div>
            )}
            <input type="file" accept="image/*" onChange={handlePictureUpload} />
            <button onClick={handleSubmit}>Change Profile Picture</button>
        </div>
    );
}

export default ProfilePictureMenu;
