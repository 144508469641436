import React, { useState, useContext } from "react";
import "./index.css";
import styled from "styled-components";
import { sidebarData } from "./SidebarData.js";
import SubMenu from "./SubMenu";
import { IconContext } from "react-icons";
import { GiHamburgerMenu } from "react-icons/gi";
import { RxCross1 } from "react-icons/rx";
import { Link, useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import { UserContext } from "./UserContext";

const SidebarIconOpen = styled(Link)`
    margin-left: 2rem;
    font-size: 2rem;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
`;

const SidebarIconClose = styled(Link)`
    margin-left: 2rem;
    font-size: 2rem;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const SidebarNav = styled.nav`
    background: #15171c;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: ${({ sidebar }) => (sidebar === true ? "0" : "-100%")};
    transition: 350ms;
    z-index: 10;

    @media screen and (max-width: 768px) {
        width: 100%;
    }
`;


const SidebarWrap = styled.div`
    width: 100%;
`;

const SignOutButton = styled.div`
    margin-right: 20px;
    padding: 10px;
    text-align: center;
    background-color: #ff4d4d;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
        background-color: #ff1a1a;
    }
`;

export default function Sidebar({ setSidebarOpen }){
    const [sidebar, setSidebar] = useState(false);
    const navigate = useNavigate();
    const auth = getAuth();
    const { role } = useContext(UserContext);

    function toggleSidebar(){
        setSidebar(!sidebar);
        if (setSidebarOpen) {
            setSidebarOpen(!sidebar);
        }
    }

    const filteredSidebarData = sidebarData.filter((item) => {
        if (item.title === "Scheduling" && role !== "Professor") {
            return false; // Only show Scheduling for Professors
        }
        return true; 
    });

    const handleSignOut = async () => {
        try {
            await signOut(auth);
            navigate("/"); // Redirect to homepage or login page after sign-out
        } catch (error) {
            console.error("Error signing out: ", error);
        }
    };

    return(
        <>
            <IconContext.Provider value={{color: "#000"}}>
                <SidebarIconOpen to="#">
                    <GiHamburgerMenu onClick={toggleSidebar}/>
                </SidebarIconOpen>
            </IconContext.Provider>
            <IconContext.Provider value={{color: "#FFF"}}>
                <SidebarNav sidebar={sidebar}>
                    <SidebarWrap>
                        <SidebarIconClose to="#">
                            <RxCross1 onClick={toggleSidebar}/>
                        </SidebarIconClose>
                        {filteredSidebarData.map((item, index) => {
                            return (
                                <SubMenu
                                    item={item}
                                    key={index}
                                    />
                            );
                        })}
                        <SignOutButton onClick={handleSignOut}>
                        Sign Out
                        </SignOutButton>
                    </SidebarWrap>
                </SidebarNav>
            </IconContext.Provider>
        </>
        
    );
}

