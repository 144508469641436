import { useEffect, useContext, useState } from "react";
import "./UpdateAccount.css";
import { validateField, emailExists, usernameExists } from "./inputValidation";
import { doc, setDoc } from "firebase/firestore";
import { db, auth } from "./Firebase/firebase";
import { getAuth, updateProfile } from "firebase/auth";
import { UserContext } from "./UserContext";
import PageHeader from "./Webpages/PageHeader";
import { deleteDoc } from "firebase/firestore";

function UpdateAccountPage() {
	const { currentUser } = useContext(UserContext);

	const [inputFieldData, setInputFieldData] = useState({
		email: currentUser.email || "",
		emailVerified: currentUser.emailVerified || true,
		firstName: currentUser.firstName || "",
		lastName: currentUser.lastName || "",
		role: currentUser.role || "Student",
		username: currentUser.username || "",
	});

	const [inputFieldErrors, setInputFieldErrors] = useState({});

	useEffect(() => {
		if (currentUser) {
			console.log("Current User:", currentUser); // Debugging line
			const displayName = currentUser.displayName || "";
			const [dFirstName, dLastName] = displayName.split(" ");
			setInputFieldData({
				email: currentUser.email,
				emailVerified: currentUser.emailVerified || true,
				firstName: dFirstName,
				lastName: dLastName,
				role: currentUser.role || "Student",
				username: currentUser.username,
			});
		}
	}, [currentUser]);

	const updateUserProfile = async (
		email,
		emailVerified,
		firstName,
		lastName,
		role,
		username
	) => {
		try {
			const user = getAuth().currentUser;

			if (!user) {
				throw new Error("No user is signed in");
			}

			await updateProfile(user, {
				displayName: `${firstName} ${lastName}`,
			});

			const userDoc = doc(db, "users", user.uid);
			await setDoc(
				userDoc,
				{
					email,
					emailVerified,
					firstName,
					lastName,
					role,
					username,
				},
				{ merge: true }
			);

			alert("Profile updated successfully!");
		} catch (error) {
			console.error("Error updating profile: ", error);
			alert("Failed to update profile");
		}
	};

	const handleChange = (event) => {
		const { name, value, files } = event.target;
		if (name === "profilePicture") {
			setInputFieldData({
				...inputFieldData,
				[name]: files[0],
			});
		} else {
			setInputFieldData({
				...inputFieldData,
				[name]: value,
			});
		}
	};
	const deleteUserAccount = async () => {
		const user = getAuth().currentUser;
		//delete user from firebase
		if (user) {
			user
				.delete()
				.then(() => {
					console.log("User account deleted successfully.");
					window.location.href = "/";
				})
				.catch((error) => {
					console.error("Error deleting user account:", error);
				});
		} else {
			console.error("No user is currently logged in.");
			alert("Failed to delete account");
		}
		//delete current user from firestore
		const userDoc = doc(db, "users", user.uid);
		await deleteDoc(userDoc);
		
		
		//Log out
		auth.signOut();
		
		//return to home page
		window.location.href = "/";
	};

	const handleDeleteAccount = (event) => {
		event.preventDefault();
		if (
			window.confirm(
				"Are you sure you want to delete your account? This action cannot be undone."
			)
		) {
			deleteUserAccount();
		}
	};

	const handleSubmit = (event) => {
		event.preventDefault();

		const {
			email,
			emailVerified = true,
			firstName,
			lastName,
			role,
			username,
		} = inputFieldData;
		updateUserProfile(
			email,
			emailVerified,
			firstName,
			lastName,
			role,
			username
		);
		window.location.href = "/";
	};

	return (
		<div>
			<PageHeader />
			<form
				id="UpdateAccountForm"
				onSubmit={handleSubmit}
				style={{ display: "block" }}>
				<div id="updateAccountContents" style={{ textAlign: "center" }}>
					<div>
						<label>
							<b>First Name: </b>
							<input
								type="text"
								name="firstName"
								minLength={1}
								maxLength={12}
								value={inputFieldData.firstName}
								onChange={handleChange}
								placeholder="First Name"
								required
							/>
							<div>
								{inputFieldErrors.firstName && (
									<span className="error">{inputFieldErrors.firstName}</span>
								)}
							</div>
						</label>
						<br />
						<label>
							<b>Last Name: </b>
							<input
								type="text"
								name="lastName"
								minLength={1}
								maxLength={18}
								value={inputFieldData.lastName}
								onChange={handleChange}
								placeholder="Last Name"
								required
							/>
							<div>
								{inputFieldErrors.lastName && (
									<span className="error">{inputFieldErrors.lastName}</span>
								)}
							</div>
						</label>
						<br />
						<label>
							<b>Username: </b>
							<input
								type="text"
								name="username"
								minLength={4}
								maxLength={16}
								value={inputFieldData.username}
								onChange={handleChange}
								placeholder="username"
								required
							/>
							<div>
								{inputFieldErrors.username && (
									<span className="error">{inputFieldErrors.username}</span>
								)}
							</div>
						</label>
						<br />
						<label>
							<b>Password: </b>
							<input
								type="password"
								name="password"
								minLength={8}
								maxLength={16}
								value={inputFieldData.password}
								onChange={handleChange}
								placeholder="Password"
							/>
							<div>
								{inputFieldErrors.password && (
									<span className="error">{inputFieldErrors.password}</span>
								)}
							</div>
						</label>
						<br />
						<label>
							<b>Email: </b>
							<input
								type="email"
								name="email"
								maxLength={40}
								value={inputFieldData.email}
								onChange={handleChange}
								placeholder="email@csus.edu"
								required
							/>
							<div>
								{inputFieldErrors.email && (
									<span className="error">{inputFieldErrors.email}</span>
								)}
							</div>
						</label>
						<br />
						<label style={{ textAlign: "right", margin: "10px" }}>
							Student
							<input
								type="radio"
								name="role"
								value="Student"
								style={{ textAlign: "right" }}
								checked={inputFieldData.role === "Student"}
								onChange={handleChange}
							/>
						</label>
						<label style={{ textAlign: "right", margin: "10px" }}>
							Professor
							<input
								type="radio"
								name="role"
								value="Professor"
								checked={inputFieldData.role === "Professor"}
								onChange={handleChange}
							/>
						</label>

						<div></div>
						<br />
						<label>
							<b>Profile Picture: </b>
							<input
								type="file"
								name="profilePicture"
								onChange={handleChange}
							/>
							<div>
								{inputFieldErrors.profilePicture && (
									<span className="error">
										{inputFieldErrors.profilePicture}
									</span>
								)}
							</div>
						</label>
						<br />
						<button onClick={handleSubmit} type="submit">
							Update Account
						</button>
						<button onClick={handleDeleteAccount} type="submit">
							Delete Account
						</button>
						<br />
					</div>
				</div>
			</form>
		</div>
	);
}

export default UpdateAccountPage;
