import React, {useEffect, useState} from 'react';
import { getDatabase, ref, get, child} from 'firebase/database';
import {app, url } from "./Firebase/firebase.js";

const UserAdvisors = () => {
    const dbRef = ref(getDatabase(app, url));
    const [advisors, setAdvisors] = useState([]);

    useEffect(() => {
        //CODE TO BE ADDED
        //find list of users advisors and pull from database
        const getAdvisors = async () => {
            
            //code to grab advisor list using get()
            get(child(dbRef, 'advisors/${advisorId}')).then((snapshot) => {
                if (snapshot.exists()) {
                    //fill advisors with data
                } else {
                    console.log("No advisors available");
                }
            }).catch((error) => {
                console.error(error);
            });
            
        };

        getAdvisors();

    }, []);

    //display
    return (
        <div>
            <h1>Lab Advisors</h1>
            {
                <p>No Advisors to display</p> 
                //code to loop through advisors
            }
        </div>
    );

};

export default UserAdvisors;