/**
 * Component to display the current classes of a user.
 * 
 * This component fetches the list of classes from an API endpoint and displays them.
 * If no classes are fetched, it displays a mock list of classes for testing purposes.
 * 
 * @component
 * @example
 * return (
 *   <UserClasses />
 * )
 * 
 * @returns {JSX.Element} The rendered component displaying the list of classes.
 */
import React, { useState, useEffect } from "react";

import AddClassFromList from "./Classes/addClassFromList.js";

/* Menu to show current classes of a user
Acceptance Criteria: Should show a list of classes joined for the user */

//TODO load page into user profile page
const classesUrl = "/api/user/classes"; //replace with actual API endpoint



/**
 * UserClasses component fetches and displays a list of classes.
 * 
 * This component uses two `useEffect` hooks:
 * 1. The first `useEffect` fetches classes from a mock API and updates the state.
 * 2. The second `useEffect` sets mock classes for testing if the classes array is empty.
 * 
 * @component
 * @example
 * return (
 *   <UserClasses />
 * )
 * 
 * @returns {JSX.Element} A React component that displays a list of classes.
 */
function UserClasses() {
	const [classes, setClasses] = useState([]);


	useEffect(() => {
		// Mock API call to fetch classes
		const fetchClasses = async () => {
			try {
				const response = await fetch(classesUrl);
				const contentType = response.headers.get("content-type");
				if (
					response.ok &&
					contentType &&
					contentType.includes("application/json")
				) {
					const data = await response.json();
					setClasses(data);
				} else {
					const errorText = await response.text();
					throw new Error(`Failed to fetch classes: ${errorText}`);
				}
			} catch (error) {
				console.error(error);
			}
		};

		fetchClasses();
	}, []);
	//mock classes for testing
	useEffect(() => {
		// Mock classes for testing
		if (classes.length === 0) {
			const mockClasses = [
				{ name: "Math 101" },
				{ name: "History 202" },
				{ name: "Science 303" },
			];
			setClasses(mockClasses);
		}
	}, [classes.length]);

	return (
		<div>
			<h1>Classes</h1>
			{classes.map((classItem, index) => (
				<div key={index}>{classItem.name}</div>
			))}
				<AddClassFromList />
		</div>
	);
}

export default UserClasses;
