
const firebase = require("firebase/app");
require("firebase/auth");
require("firebase/firestore");
require("firebase/functions");

const firebaseConfig = {
	// The Firebase project configuration
};

firebase.initializeApp(firebaseConfig);

/**
 * Sends an email using Firebase Cloud Functions.
 * @param {string} to - The email address of the recipient.
 * @param {string} subject - The subject of the email.
 * @param {string} body - The body content of the email.
 * @returns {Promise<void>} - A promise that resolves when the email is sent successfully.
 */
async function sendEmail(to, subject, body) {
	try {
		const sendEmailFunction = firebase.functions().httpsCallable("sendEmail");
		const response = await sendEmailFunction({ to, subject, body });
		console.log("Email sent successfully:", response.data);
	} catch (error) {
		console.error("Error sending email:", error);
	}
}

// The email details
const to = 'recipient@example.com';
const subject = 'Hello from Firebase!';
const body = 'This is the body of the email.';

// Call the function to send the email
sendEmail(to, subject, body);


