import React from "react";
import "./CSS/BaseWebsite.css";

function homePage() {
    return (
		<div>
			<header>
					<div id="logoBox" onClick={() => window.location.href = 'https://csus.edu'} style={{ cursor: 'pointer', }}>
				</div>
			</header>            <h2>
                <p>
                    <a href="LoginPortal.js" style={{textAlign: "right"}}>Professor Portal</a>
                </p>
            </h2>
			<form id="loginForm">
				<div id="loginContents" style={{ textAlign: "center" }}>
					<div id="loginError">
					<label>username/password incorrect</label></div>
					<div>
					<label>
					<input type="text"
							name="username"
							pattern="[A-Za-z0-9]+"
							minLength={4}
							maxLength={16}
							placeholder="Username"/>
					</label>
						<br />
						<label>
							<input
								type="password"
								name="password"
								minLength={6}
								maxLength={12}
								placeholder="Password"
							/>
						</label>
						<br />
						<button type="submit">Login</button>
						<br />
					</div>
				</div>
				<p>
                    <a href="CreateAccount.js" style={{textAlign: "rleft"}}>Create Account</a>
                </p>
			</form>
		</div>
    ); // return
} // function homePage()
export default homePage;