import React, { useRef, useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { db } from "../Firebase/firebase";
import {
	collection,
	addDoc,
	getDocs,
	getDoc,
	updateDoc,
	doc,
	setDoc,
} from "firebase/firestore";
import "./userCalendar.css";
import getUserIdFromCookie from "../userProfile/getUserIdFromCookie";
import getUserNameFromCookie from "../userProfile/getUserNameFromCookie";
import sendEmail from "../sendEmail";

function ProfessorCalendar() {
	const calendarRef = useRef(null);
	const [availability, setAvailability] = useState([]);
	const [meetings, setMeetings] = useState([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [start, setStart] = useState("");
	const [isMeetingModalOpen, setIsMeetingModalOpen] = useState(false);
	const [end, setEnd] = useState("");
	const [meetingTitle, setMeetingTitle] = useState("");
	const [meetingDescription, setMeetingDescription] = useState("");
	const [userID, setUserID] = useState("2");
	const [userName, setUserName] = useState("");

	const [AdvUserID, setAdvUserID] = useState("");
	const [advisorAvail, setadvisorAvail] = useState([]);
	const [selectedEvent, setSelectedEvent] = useState("");
	const [attendees, setAttendees] = useState("");
	const [color, setColor] = useState("green");

	useEffect(() => {
		//fetch user id from memory or cookies
		if (!userID) {
			getUserIdFromCookie().then((id) => {
				setUserID(id);
			});
		}
		//fetch user name from cookies
		if (!userName) {
			getUserNameFromCookie().then((name) => {
				setUserName(name);
			});
		}
	}, [userID, userName]);

	//fetch advisor id from firestore
	useEffect(() => {
		if (!AdvUserID) {
			if (!userID) {
				const fetchAdvisorId = async () => {
					try {
						const userDoc = await getDocs(collection(db, "users"));
						const userData = userDoc.docs.find((doc) => doc.id === userID);
						if (userData) {
							const advisorId = userData.data().advisorId;
							setAdvUserID(advisorId);
						} else {
							console.error("User data not found in Firestore");
						}
					} catch (error) {
						console.error("Error fetching advisor ID: ", error);
					}
				};
				fetchAdvisorId();
			} else {
				console.error("User ID not found in cookies");
			}
		}
	}, [AdvUserID, userID]);

	// Fetch availability and meetings from Firestore
	useEffect(() => {
		//fetching user data from firestore
		const fetchEvents = async () => {
			try {
				//available times
				const availabilitiesData = await getDocs(
					collection(db, "availabilities")
				);
				const fetchedAvailability = availabilitiesData.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
				}));
				setAvailability(fetchedAvailability);

				//set meetings
				const meetingsData = await getDocs(collection(db, "meetings"));
				const fetchedMeetings = meetingsData.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
					color: "blue", // color blue for meetings/events
				}));
				setMeetings(fetchedMeetings);
			} catch (error) {
				console.error("Error fetching events: ", error);
			}
		};

		//fetch advisor availability from firestore
		const fetchAdvisorEvents = async () => {
			try {
				const advisorAvailabilitiesData = await getDocs(
					collection(db, "advisorAvailabilities")
				);
				const fetchedAdvisorAvailability = advisorAvailabilitiesData.docs.map(
					(doc) => ({
						id: doc.id,
						...doc.data(),
						color: "red", // color red for advisor availability
					})
				);
				setadvisorAvail(fetchedAdvisorAvailability);
			} catch (error) {
				console.error("Error fetching advisor availability: ", error);
			}
		};
		fetchEvents();
		fetchAdvisorEvents();
	}, []);

	// Open modal function for button click
	const handleCreateAvailabilityClick = (selectInfo) => {
		const { startStr, endStr } = selectInfo;
		setStart(startStr);
		setEnd(endStr);
		openModal();
	};

	// Function to check for overlapping times
	const isOverlapping = (newEvent) => {
		console.log("Checking for overlaps with new event:", newEvent);
		console.log("Existing events:", availability);
		return availability
			.filter((event) => event.userID === userID) //only check for overlaps with the user's own availabilities
			.some((event) => {
				const existingStart = new Date(event.start).getTime();
				const existingEnd = new Date(event.end).getTime();
				const newStart = new Date(newEvent.start).getTime();
				const newEnd = new Date(newEvent.end).getTime();

				// Check if the new event overlaps with an existing event
				return (
					(newStart >= existingStart && newStart < existingEnd) ||
					(newEnd > existingStart && newEnd <= existingEnd) ||
					(newStart <= existingStart && newEnd >= existingEnd)
				);
			});
	};

	// handle submission and adds to firestore
	const handleSubmit = async () => {
		const newAvailability = {
			userID: userID,
			title: "Available Slot",
			start,
			end,
			allDay: false,
			attendees: "",
			color: "green", // color green for availability
		};

		// Check for overlapping times
		if (isOverlapping(newAvailability)) {
			alert("The selected time overlaps with an existing availability.");
			console.log("Overlapping times detected.");
			return;
		} else {
			try {
				// Save availability to firestore
				console.log("Adding new availability to Firestore:", newAvailability);
				await addDoc(collection(db, "availabilities"), newAvailability);
				//save to state
				setAvailability((prevAvailability) => [
					...prevAvailability,
					newAvailability,
				]);
				alert("Availability saved to database!");
				closeModal(); //close the modal after saving
			} catch (error) {
				console.error("Error saving availability: ", error);
				alert("Failed to save availability.");
			}
		}
	};

	const handleMeetingClick = (selectInfo) => {
		console.log("event stuff   ", selectInfo.event.color);
	
		const event = selectInfo.event;
		setMeetingTitle(event.title);
		setMeetingDescription(event.extendedProps.description);
		setStart(event.startStr);
		setEnd(event.endStr);
		setAttendees(event.attendees);
		setColor(event.color);
		setIsMeetingModalOpen(true);
		setSelectedEvent(selectInfo); // Save selectInfo to state
	};

	const closeMeetingModal = () => {
		setIsMeetingModalOpen(false);
	};

	const handleMeetingSubmit = async () => {
		try {
			if (!selectedEvent || !selectedEvent.event || !selectedEvent.event.id) {
				throw new Error(
					"Selected event is not defined or does not have an ID."
				);
			}

			const newMeeting = {
				title: meetingTitle || "Untitled Meeting",
				description: meetingDescription || "",
				start: start || new Date().toISOString(),
				end: end || new Date().toISOString(),
				attendees: attendees || [],
				userID: userID || "",
				color: color || "blue",
			};

			// Reference to the document you want to update
			const meetingDocRef = doc(db, "availabilities", selectedEvent.event.id);

			// Check if the document exists
			const docSnapshot = await getDoc(meetingDocRef);
			if (docSnapshot.exists()) {
				// Update the document
				await updateDoc(meetingDocRef, newMeeting);
			} else {
				// Create the document if it does not exist
				await setDoc(meetingDocRef, newMeeting);
			}
			//remove old meeting from state memory
			setAvailability((prevAvailability) =>
				prevAvailability.filter(
					(event) =>
						!(
							new Date(event.start) < new Date(newMeeting.end) &&
							new Date(event.end) > new Date(newMeeting.start)
						)
				)
			);
			// Update state with new meeting
			setMeetings((prevMeetings) => [...prevMeetings, newMeeting]);

			alert("Meeting request submitted!");
		} catch (error) {
			console.error("Error submitting meeting request: ", error);
			alert("Failed to submit meeting request.");
		}

		//Send notification
		const to = advisorAvail.find((advisor) => advisor.userID === AdvUserID).email;
		const subject = "Meeting Request";
		const body = `You have a new meeting request from ${userName} for ${start}`;
		
		sendEmail(to, subject, body)

		closeMeetingModal();
	};

	// close modal function
	const closeModal = () => {
		setIsModalOpen(false);
	};

	// open modal function
	const openModal = () => {
		setIsModalOpen(true);
	};

	return (
		<div className="container">
			<div id="calendar">
				<FullCalendar
					ref={calendarRef}
					plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
					initialView="dayGridMonth"
					selectable={true}
					selectMirror={true}
					headerToolbar={{
						left: "prev,next createAvailabilityButton",
						center: "title",
						right: "today,dayGridMonth,timeGridWeek,timeGridDay",
					}}
					customButtons={{
						createAvailabilityButton: {
							text: "Set Availability",
							click: handleCreateAvailabilityClick,
						},
					}}
					events={[...availability, ...meetings, ...advisorAvail]}
					eventClick={handleMeetingClick}
				/>

				{isModalOpen && (
					<div
						role="dialog"
						aria-labelledby="modal-title"
						className="custom-modal">
						<div className="custom-modal-content">
							<span className="close" onClick={closeModal}>
								&times;
							</span>
							<h2 id="modal-title">Set Availability Time</h2>
							<form>
								<div className="form-group">
									<label htmlFor="start-time">Start</label>
									<input
										id="start-time"
										type="datetime-local"
										className="form-control"
										value={start}
										onChange={(e) => setStart(e.target.value)}
										aria-label="Start"
									/>
								</div>

								<div className="form-group">
									<label htmlFor="end-time">End</label>
									<input
										id="end-time"
										type="datetime-local"
										className="form-control"
										value={end}
										onChange={(e) => setEnd(e.target.value)}
										aria-label="End"
									/>
								</div>

								<button type="button" className="btn" onClick={handleSubmit}>
									Save Availability
								</button>
							</form>
						</div>
					</div>
				)}

				{isMeetingModalOpen && (
					<div
						role="dialog"
						aria-labelledby="meeting-modal-title"
						className="custom-modal">
						<div className="custom-modal-content">
							<span className="close" onClick={closeMeetingModal}>
								&times;
							</span>
							<h2 id="meeting-modal-title">Meeting Request</h2>
							<form>
								<div className="form-group">
									<label htmlFor="meeting-title">Title</label>
									{selectedEvent.event.backgroundColor === "blue" ? (
										<input
											id="meeting-title"
											type="text"
											className="form-control"
											value={meetingTitle}
											readOnly
											aria-label="Meeting Title"
										/>
									) : (
										<input
											id="meeting-title"
											type="text"
											className="form-control"
											value={meetingTitle}
											onChange={(e) => setMeetingTitle(e.target.value)}
											aria-label="Meeting Title"
										/>
									)}
								</div>
											
								<div className="form-group">
									<label htmlFor="meeting-description">Description</label>
									{selectedEvent.event.backgroundColor === "blue" ? (
										<textarea
											id="meeting-description"
											className="form-control"
											value={meetingDescription}
											readOnly
											aria-label="Meeting Description"
										/>
									) : (
										<textarea
											id="meeting-description"
											className="form-control"
											value={meetingDescription}
											onChange={(e) => setMeetingDescription(e.target.value)}
											aria-label="Meeting Description"
										/>
									)}
								</div>
						
								{selectedEvent.event.backgroundColor !== "blue" && (
									<button
										type="button"
										className="btn"
										onClick={handleMeetingSubmit}>
										Request Meeting
									</button>
								)}
							</form>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

export default ProfessorCalendar;
