import React from "react";
import * as RxIcons from "react-icons/rx";

/*
How to add a item to the sidebar
{
    title: "insert title"
    path: "insert path"
    icon: <pass it an icon from react-icons/> 
    icons can be found here https://react-icons.github.io/react-icons/

    if it is a dropdown, include as well
    iconClosed: <RxIcons.RxTriangleDown />, (icons subject to change)
    iconOpened: <RxIcons.RxTriangleUp />,

    subNav is where your child elements under the dropdown will be listed
    subNav: [
        {
            title: "insert title"
            path: "insert path"
            icon: <pass it an icon from react-icons/> 
        }
    repeat for as many items as you want in the dropdown
    ]
}
*/




export const sidebarData =[
    {
        title: "Login Portal",
        path: "LoginPortal",
        icon: <RxIcons.RxAvatar />,
    },
    {
        title: "Account",
        icon: <RxIcons.RxBookmark />,
        iconClosed: <RxIcons.RxTriangleDown />,
        iconOpened: <RxIcons.RxTriangleUp />,
        
        subNav: [
            {
                title: "Create Account",
                path: "/CreateAccount",
                icon: <RxIcons.RxBookmark />,
            },
            {
                title: "Update Account",
                path: "/updateAccount",
                icon: <RxIcons.RxBookmark />,
            },
        ],
    },
    {
        title: "Calendar",
        path: "/Calendar",
        icon: <RxIcons.RxCalendar />,
    },
    {
        title: "Team",
        path: "#",
        icon: <RxIcons.RxPerson />,
        iconClosed: <RxIcons.RxTriangleDown />,
        iconOpened: <RxIcons.RxTriangleUp />,

        subNav: [
            {
                title: "Group Manager",
                path: "/GroupManager",
                icon: <RxIcons.RxFileText/>
            },
            {
                title: "Group List",
                path: "/GroupList",
                icon: <RxIcons.RxListBullet/>
            },
        ],
    },
    {
        title: "Scheduling",
        path: "/ProfessorScheduling",
        icon: <RxIcons.RxDashboard />,
    },
];