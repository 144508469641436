import { getDocs, collection, query, where } from "firebase/firestore";
import { db } from "./Firebase/firebase";

export const validateField = (name, value) => {
switch (name) {
    case 'firstName':
        if (!/^[A-Za-z]+$/.test(value)) { // Check if any illegal chars used
            return `*First name must be upper or lower case letters with no numbers or special characters`;
        }
        break;
    case 'lastName':
        if (!/^[A-Za-z]+$/.test(value)) { // Check if any illegal chars used
            return `*Last name must be upper or lower case letters with no numbers or special characters`;
        }
        break;  
    case 'password':     
    if (!value) {
        return '*Password cannot be null';
    }
    
    //password validation
        var lowerCase = /[a-z]/,
            upperCase = /[A-Z]/,
            number = /[0-9]/,
            symbol = /[^a-zA-Z0-9]/;
        if (upperCase.test(value) === false) {                          //check that an upper case character is used
            return '*Passwords must contain an upper case letter';
        } else if (lowerCase.test(value) === false) {                   //check that a lower case character is used
            return '*Passwords must contain a lower case letter';
        } else if (number.test(value) === false) {                      //check that a number is used
            return '*Passwords must contain a number';
        } else if (symbol.test(value) === false) {                      //check that a special character is used
            return '*Passwords must contain a special character';
        } else if (value.length < 7) {
            return '*Passwords must be between 8 and 16 characters'
        }
        else if (value.length > 16) {
            return '*Passwords must be between 8 and 16 characters'
        }
        break;
    case 'email':
    if (!value) {
        return '*Email cannot be null';
    }
        if (!/.+@csus\.edu$/.test(value)) { // Check for csus.edu email addresses
            return '*Email must be a valid csus.edu address';
        }
        break;
        default:
        break;
    }
    return '';
};

// Function for checking for duplicate email
export const emailExists = async (email) => {
    try {
        // Query Firestore to find users with the provided email
        const q = query(collection(db, "users"), where("email", "==", email));
        const querySnapshot = await getDocs(q);
        return !querySnapshot.empty; // Returns true if the email exists
    } catch (error) {
        console.error("Error checking email existence:", error);
        throw error;
    }
};

// Function for checking for duplicate username
export const usernameExists = async (username) => {
    try {
        const q = query(collection(db, "users"), where("username", "==", username));
        const querySnapshot = await getDocs(q);
        return !querySnapshot.empty; 
    } catch (error) {
        console.error("Error checking username existence:", error);
        throw error;
    }
};